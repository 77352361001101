import * as React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Header from "../components/header"
import FixedFooter from "../components/footer"

// styles
const headingStyles = {
    marginTop: 140,
    marginBottom: 100,
}

// markup
const RegionPage = () => {
  return (
    <main>
      <title>Visiting Western Loudoun County</title>
      <Header></Header>
      <Container style={headingStyles}>
        <Row>
            <div className="col-sm-8">
            <h2>Enjoy the Region</h2>
            <p>
              Fieldstone Farm is located in the far north western corner of Loudoun County, about a 75 minute drive from Washington DC. Located in the heart of the Hillsboro Valley Wine 
              Region, we have over 20 wineries within five miles of the farm. 
              We also have:
            </p>
            <h3>History</h3>
            <p>
            Visiting historic landmarks and national historic trust properties:
            <ul>
                    <li>Oatlands</li>
                    <li>Harpers Ferry</li>
                    <li>Morven Park</li>
            </ul>
            </p>
            <h3>Shopping</h3>
            <p>
            Go antiquing and shop in towns and villages in the surrounding area:
            <ul>
                    <li>Leesburg</li>
                    <li>Middleburg</li>
                    <li>Purcellville</li>
            </ul>
            </p>
            <h3>Outdoor Activities</h3>
            <p>
            Enjoy biking and hiking:
            <ul>
                    <li>W&OD Trail (5 miles away, and leads all the way to Washington DC)</li>
                    <li>Chapman DeMary Nature Trail</li>
                    <li>Appalachian Trail: Keyes Gap to Blackburn Trail Center (10 miles away)</li>
                    <li>Blue Ridge Center for Environmental Stewardship</li>
            </ul>
            </p>
            <h3>Great Food</h3>
            <p>
            Enjoy our local restaurants!
            <ul>
                <li>Magnolias at the Mill</li>
                <li>Lightfoot</li>
                <li>Grandale Farm</li>
                <li>Hunters Head</li>
                <li>Market Table Bistro</li>
                <li>WK Hearth</li>
            </ul>
            </p>            
            </div>
            <div className="col-sm-4">
                <Row className="align-items-center">
                    <Col className="d-flex justify-content-center">
                    </Col>
                </Row>                
            </div>            
        </Row>        
      </Container>
      <FixedFooter/>
    </main>
  )
}

export default RegionPage